import _isNil from 'lodash-es/isNil';
import { NavigationEntry } from '@/node_modules/@osp/design-system/types/navigation';
import { ClsOptimizationMixin } from '~/components/mixins/cls-optimization-mixin';
import { Bool, Component, Prop, Vue } from '~/app-utils/decorators';
import { useMediaqueryStore } from '~/@api/store/mediaqueryApi';
import { useRoutingStore } from '~/@api/store/routingApi';
import { PageTypes } from '~/@api/store.types';

@Component({
	mixins: [ClsOptimizationMixin],
})
export default class SideNavigationBar extends Vue {
	private preparedItems = 0;
	private clsPreparedComponents = {
		AllItems: false,
	};

	@Prop({ required: true })
	public entries: NavigationEntry[];

	@Prop({ required: true })
	public navLabel: string;

	@Prop()
	public parent: string;

	@Prop({ default: false })
	public hasOpenedParent: boolean;

	@Bool()
	public withoutSelected: boolean;

	get visibleEntries(): NavigationEntry[] {
		return this.entries.filter((entry) => this.visible(entry));
	}

	get getStyles() {
		const { state: mediaqueryState } = useMediaqueryStore(this.$store);

		return [
			this.$style.sideNavigationBarDefault,
			this.$style[mediaqueryState.device.category],
			this.$style[mediaqueryState.device.orientation],
		];
	}

	private isOpened(entry: NavigationEntry) {
		return entry.selected || (entry.children || []).some((child) => this.isOpened(child));
	}

	private visible(entry: NavigationEntry) {
		return (
			!this.withoutSelected ||
			(!entry.selected && !(entry.children || []).some((child) => child.selected))
		);
	}

	label(category: string) {
		return this.$t(this.$i18nKeys.sideNavigation.label, { category });
	}

	calcId(index: number) {
		return `${this.parent || this.navLabel}-${index}`;
	}

	isOpenable(entry: NavigationEntry) {
		return entry.children && entry.children.length > 0;
	}

	tag(entry: NavigationEntry) {
		if (
			[PageTypes.BRAND, PageTypes.CONTENT].includes(useRoutingStore(this.$store).state.pageType)
		) {
			// Use <a> HTML tag for all indexed (search bots) entries otherwise use <span>
			return entry.index ? 'a' : 'span';
		}

		// Use <a> HTML tag for all visible entries otherwise use <span> (items in collapsed menu)
		return this.hasOpenedParent || this.isOpened(entry) || _isNil(this.parent) ? 'a' : 'span';
	}

	countItemPrepared(): void {
		this.preparedItems++;

		if (this.preparedItems >= this.visibleEntries.length) {
			this.clsPreparedComponents.AllItems = true;
		}
	}
}
