import { Component, Prop, Vue } from '@/app-utils/decorators';

@Component
export default class WithRoot extends Vue {
	@Prop({ required: true })
	public show: boolean;

	public render(_h) {
		const children = (this.$slots.default || []).filter((vnode) => vnode.tag); // remove unnecessary text nodes

		if (children.length > 0) {
			if (this.show) {
				return children[0];
			} else if (children[0].children.length > 0) {
				return children[0].children[0];
			}
		}
	}
}
