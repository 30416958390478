var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WithRoot',{attrs:{"show":!_vm.parent}},[_c('nav',{class:_vm.getStyles},[_c('ul',[_vm._l((_vm.visibleEntries),function(entry,index){
var _obj;
return [_c('li',{key:index,class:[( _obj = {}, _obj[_vm.$style.selected] = entry.selected, _obj )]},[(_vm.isOpenable(entry))?[_c('input',{attrs:{"id":_vm.calcId(index),"type":"checkbox"},domProps:{"checked":_vm.isOpened(entry)}}),_c('label',{attrs:{"for":_vm.calcId(index),"aria-label":_vm.label(entry.label)}}),(entry.href)?_c('RouterLink',{class:_vm.$style.linkBranch,attrs:{"to":entry.href,"custom":""},on:{"hook:mounted":function($event){return _vm.countItemPrepared()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c(_vm.tag(entry),_vm._b({tag:"Component",on:{"click":navigate}},'Component',{
									href: _vm.tag(entry) === 'a' ? entry.seoHref : null,
								},false),[_vm._v(" "+_vm._s(entry.label)+" ")])]}}],null,true)}):_c('span',{class:_vm.$style.linkBranch,on:{"click":function (e) { return e.target.previousElementSibling.click(); }}},[_vm._v(" "+_vm._s(entry.label)+" ")]),(entry.additionalLabel)?_c('span',{class:_vm.$style.count},[_vm._v(" ("+_vm._s(entry.additionalLabel)+") ")]):_vm._e(),_c('SideNavigationBar',{class:_vm.$style.nested,attrs:{"entries":entry.children,"parent":_vm.calcId(index),"has-opened-parent":_vm.isOpened(entry),"nav-label":_vm.navLabel,"without-selected":_vm.withoutSelected}})]:(entry.href)?[_c('RouterLink',{class:_vm.$style.linkLeaf,attrs:{"to":entry.href,"custom":""},on:{"hook:mounted":function($event){return _vm.countItemPrepared()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
								var navigate = ref.navigate;
return [_c(_vm.tag(entry),_vm._b({tag:"Component",on:{"click":navigate}},'Component',{ href: _vm.tag(entry) === 'a' ? entry.seoHref : null },false),[_vm._v(" "+_vm._s(entry.label)+" ")])]}}],null,true)}),(entry.additionalLabel)?_c('span',{class:_vm.$style.count},[_vm._v(" ("+_vm._s(entry.additionalLabel)+") ")]):_vm._e()]:_vm._e()],2)]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }